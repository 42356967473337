import TestSpiti1 from "../../resources/images/test-spiti-1.jpg";
import TestSpiti2 from "../../resources/images/test-spiti-2.jpg";
import TestSpiti3 from "../../resources/images/test-spiti-3.jpg";
import TestSpiti4 from "../../resources/images/test-spiti-4.jpg";
import TestOther1 from "../../resources/images/test-other-1.jpg";
import TestOther2 from "../../resources/images/test-other-2.jpg";
import TestOther3 from "../../resources/images/test-other-3.jpg";
import TestOther4 from "../../resources/images/test-other-4.jpg";
import SpitiMap from "../../resources/images/spiti-map.webp";
import Map from "../../resources/images/map.svg";

export const spitiData = {
  title: "Overlanding Spiti Expedition",
  location: "Spiti Valley",
  description:
    "The Spiti Valley Overlanding Expedition is an adventurous journey through the remote Spiti Valley in Himachal Pradesh. It features rugged off-road driving, ancient Buddhist monasteries, and stunning Himalayan landscapes. Highlights include crossing the Kunzum Pass, visiting Key Monastery, and exploring villages like Kaza and Tabo. This trip offers a perfect mix of adventure, culture, and natural beauty.",
  heroImgs: [TestSpiti1, TestSpiti2, TestSpiti3, TestSpiti4],
  map: SpitiMap,
};

export const otherData = {
  title: "Experiential Retreat In Pushkar",
  location: "Pushkar",
  description:
    "The Experiential Retreat in Pushkar offers a unique blend of relaxation, culture, and spiritual immersion in the serene town of Pushkar, Rajasthan. Known for its sacred lake and vibrant temples, the retreat allows participants to engage in mindful activities such as yoga, meditation, and cultural tours. Guests can explore the town’s rich heritage, visit the famous Brahma Temple, and experience local traditions like the Pushkar Camel Fair. This retreat is designed to rejuvenate the mind and body while providing a deep connection to the spiritual and cultural essence of Pushkar.",
  heroImgs: [TestOther1, TestOther2, TestOther3, TestOther4],
  map: Map,
};
