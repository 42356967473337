import React from "react";
import "./Navbar.css";
// import CreateArrow from "../resources/images/create-arrow.svg";
import Logo from "../resources/images/t-logo.svg";
export default function Navbar({
  isMobile,
  filter,
  button,
}: {
  isMobile: boolean;
  filter?: boolean;
  button?: string;
}) {
  return (
    <div
      className="navbar-container"
      style={
        !filter
          ? {
              position: "sticky",
              top: "0",
              zIndex: "1000",
              backgroundColor: "white",
              // filter: filter ? "blur(1.2000000476837158px)" : "none",
            }
          : { backdropFilter: "blur(10px)" }
      }
    >
      <img
        src={Logo}
        alt="logo"
        onClick={() => {
          window.location.href = "/";
        }}
        style={{ cursor: "pointer" }}
      />
      <button
        className={"create-button"}
        onClick={() => {
          if (!button) {
            window.location.href = "https://tally.so/r/mV1Mgg";
          }
        }}
      >
        <p className="create-button-text">{button ? "Book now" : "Create"}</p>
        {/* <img src={CreateArrow} alt="create-arrow" style={{ width: 33 }} /> */}
      </button>
    </div>
  );
}
