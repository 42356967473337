import React from "react";
import { useMediaQuery } from "@mui/material";
import ThriviaLogo from "../../resources/images/thrivia_logo.png";
import HomeBackground1 from "../../resources/images/home-background.png";
import HomeBackground2 from "../../resources/images/home-background-2.png";
import HomeBackground3 from "../../resources/images/white-background.svg";
import "./HomeV2.css";
import "./HomeV2Mobile.css";
import MobBack1 from "../../resources/images/mob-back-1.png";
import MobBack2 from "../../resources/images/mob-back-2.png";

export default function HomeV2() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  if (isMobile) {
    return (
      <div style={{ marginLeft: 22, marginRight: 22 }}>
        <img
          src={MobBack1}
          alt="background"
          style={{ top: 400, left: -22, position: "absolute", zIndex: -1 }}
        />
        <img
          src={MobBack2}
          alt="background"
          style={{ top: 0, right: -22, position: "absolute", zIndex: -1 }}
        />
        <div
          style={{
            marginTop: 22,

            display: "flex",
            alignItems: "center",
            gap: 11,
            cursor: "pointer",
          }}
        >
          <img src={ThriviaLogo} alt="Logo" style={{ width: 17, height: 30 }} />
          <p className="nav_logo_p">Thrivia</p>
        </div>
        <div style={{ marginTop: 74 }}>
          <div className="travel-explore-div">
            <p className="travel-explore-heading">Travel and Explore</p>
            <p className="travel-explore-subheading">
              Empowering creators to craft, enthusiasts to discover immersive
              experiences
            </p>
            <button
              className="discover-button"
              onClick={() => {
                window.location.href = "/discover";
              }}
            >
              Discover
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 127,
          }}
        >
          <div>
            <div className="travel-explore-div">
              <p className="travel-explore-heading">Create With Us</p>
              <p className="travel-explore-subheading">
                ‘Creator’ describes those who, beyond influencers recognition,
                craft new experiences.
              </p>
              <button
                className="discover-button"
                onClick={() => {
                  window.location.href = "https://tally.so/r/mV1Mgg";
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 16,
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginTop: 81,
            paddingBottom: 42,
          }}
        >
          <div style={{ flex: 1 }}>
            <div className="details-home-div">
              <p className="details-home-div-text">50 +</p>
            </div>
            <p className="details-home-text">Creators</p>
          </div>
          <div style={{ flex: 1 }}>
            <div className="details-home-div">
              <p className="details-home-div-text">100 +</p>
            </div>
            <p className="details-home-text">Resources Listed</p>
          </div>
          <div style={{ flex: 1 }}>
            <div className="details-home-div">
              <p className="details-home-div-text">25 +</p>
            </div>
            <p className="details-home-text">Experiences Hosted</p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="home_container">
      <div
        className="home-left-container"
        style={{
          backgroundImage: isMobile
            ? `url(${HomeBackground1})`
            : `url(${HomeBackground1})`,
        }}
      >
        <div
          style={{
            marginTop: 38,
            marginLeft: 74,
            display: "flex",
            alignItems: "center",
            gap: 20,
            cursor: "pointer",
          }}
        >
          <img src={ThriviaLogo} alt="Logo" />
          <p className="nav_logo_p">Thrivia</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 354,
              height: 709,
              // borderRadius: "100% 0% 0% 100%",
              // background: "white",
              backgroundImage: `url(${HomeBackground3})`,
              margin: "0px 0px 80px ",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div className="travel-explore-div">
              <p className="travel-explore-heading">Travel and Explore</p>
              <p className="travel-explore-subheading">
                Empowering creators to craft, enthusiasts to discover immersive
                experiences
              </p>
              <button
                className="discover-button"
                onClick={() => {
                  window.location.href = "/discover";
                }}
              >
                Discover
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="home-right-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 108,
            marginTop: 86,
          }}
        >
          <img
            src={HomeBackground2}
            alt="background"
            style={{ alignSelf: "flex-start" }}
          />
          <div>
            <div className="details-home-container">
              <div>
                <div className="details-home-div">
                  <p className="details-home-div-text">50 +</p>
                </div>
                <p className="details-home-text">Creators</p>
              </div>
              <div>
                <div className="details-home-div" style={{ width: 178 }}>
                  <p className="details-home-div-text">100 +</p>
                </div>
                <p className="details-home-text">Resources Listed</p>
              </div>
              <div>
                <div className="details-home-div" style={{ width: 200 }}>
                  <p className="details-home-div-text">25 +</p>
                </div>
                <p className="details-home-text">Experiences Hosted</p>
              </div>
            </div>
            <div
              className="travel-explore-div"
              style={{ marginLeft: -186, marginTop: 55, padding: "12px 24px" }}
            >
              <p className="travel-explore-heading">Create With Us</p>
              <p className="travel-explore-subheading">
                ‘Creator’ describes those who, beyond influencers recognition,
                craft new experiences.
              </p>
              <button
                className="discover-button"
                style={{ width: 257 }}
                onClick={() => {
                  window.location.href = "https://tally.so/r/mV1Mgg";
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
