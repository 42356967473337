import TestSpiti1 from "../../resources/images/test-spiti-1.jpg";
import Aman from "../../resources/images/aman.webp";
import Samarth from "../../resources/images/samarth.webp";
import TestSpiti3 from "../../resources/images/test-spiti-3.jpg";
import TestOther1 from "../../resources/images/test-other-1.jpg";
import TestOther2 from "../../resources/images/test-other-2.jpg";

export const aman = {
  image: Aman,
  name: "Aman",
  description:
    "I’m Aman, a solo traveler and software engineer with a passion for pushing boundaries. After four years of exploring offbeat destinations, I’ve combined my love for adventure and tech expertise to curate unique, immersive experiences. Whether it's teaching slacklining, organizing overlanding expeditions, or celebrating Holi in Pushkar, I’m all about creating unforgettable journeys that challenge and inspire. Join me on one of my retreats and discover something new about the world—and yourself.",
  exps: [
    {
      image: TestSpiti1,
      title: "Fun corporate retreat",
      subtitle: "Overlanding Spiti Expedition",
      description:
        "Embark on an epic overlanding expedition through the rugged terrains of Spiti Valley.",
      dates: "Fri, 10 Jan 2024 - Fri, 17 Jan 2024",
      datesMobile: "10 Jan ‘24 - 17 Jan ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Jan",
      date: "10",
      link: "/event/spiti",
    },

    {
      image: TestOther1,
      title: "Retreat with Design Team",
      subtitle: "Experiential Retreat In Pushkar",
      description:
        "Experience the spiritual essence of Pushkar through a thoughtfully curated retreat",
      dates: "Fri, 10 Jan 2024 - Fri, 17 Jan 2024",
      datesMobile: "10 Jan ‘24 - 17 Jan ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Jan",
      date: "10",
      link: "/event/pushkar",
    },

    {
      image: TestOther2,
      title: "Journey with Friends",
      subtitle: "Pushkar Expedition",
      description:
        "Experience the spiritual essence of Pushkar through a thoughtfully curated retreat",
      dates: "Fri, 24 Jan 2024 - Sat, 1 Feb 2024",
      datesMobile: "24 Jan ‘24 - 1 Feb ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Jan",
      date: "24",
      link: "/event/pushkar",
    },
    {
      image: TestSpiti3,
      title: "Meditation in solitude",
      subtitle: "Meditation Retreat in Spiti Valley",
      description:
        "Embark on an epic overlanding expedition through the rugged terrains of Spiti Valley.",
      dates: "Fri, 7 Feb 2024 - Sat, 15 Feb 2024",
      datesMobile: "7 Feb ‘24 - 15 Feb ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Feb",
      date: "7",
      link: "/event/spiti",
    },
  ],
  instagram: "https://www.instagram.com/blink_n_trip?igsh=MTMyemd2N251MTNuOA==",
};

export const samarth = {
  image: Samarth,
  name: "Samarth",
  description:
    "I’m Samarth, a solo traveler and software engineer with a passion for pushing boundaries. After four years of exploring offbeat destinations, I’ve combined my love for adventure and tech expertise to curate unique, immersive experiences. Whether it's teaching slacklining, organizing overlanding expeditions, or celebrating Holi in Pushkar, I’m all about creating unforgettable journeys that challenge and inspire. Join me on one of my retreats and discover something new about the world—and yourself.",
  exps: [
    {
      image: TestSpiti1,
      title: "Fun corporate retreat",
      subtitle: "Overlanding Spiti Expedition",
      description:
        "Embark on an epic overlanding expedition through the rugged terrains of Spiti Valley.",
      dates: "Fri, 10 Jan 2024 - Fri, 17 Jan 2024",
      datesMobile: "10 Jan ‘24 - 17 Jan ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Jan",
      date: "10",
      link: "/event/spiti",
    },

    {
      image: TestOther1,
      title: "Retreat with Design Team",
      subtitle: "Experiential Retreat In Pushkar",
      description:
        "Experience the spiritual essence of Pushkar through a thoughtfully curated retreat",
      dates: "Fri, 10 Jan 2024 - Fri, 17 Jan 2024",
      datesMobile: "10 Jan ‘24 - 17 Jan ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Jan",
      date: "10",
      link: "/event/pushkar",
    },

    {
      image: TestOther2,
      title: "Journey with Friends",
      subtitle: "Pushkar Expedition",
      description:
        "Experience the spiritual essence of Pushkar through a thoughtfully curated retreat",
      dates: "Fri, 24 Jan 2024 - Sat, 1 Feb 2024",
      datesMobile: "24 Jan ‘24 - 1 Feb ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Jan",
      date: "24",
      link: "/event/pushkar",
    },
    {
      image: TestSpiti3,
      title: "Meditation in solitude",
      subtitle: "Meditation Retreat in Spiti Valley",
      description:
        "Embark on an epic overlanding expedition through the rugged terrains of Spiti Valley.",
      dates: "Fri, 7 Feb 2024 - Sat, 15 Feb 2024",
      datesMobile: "7 Feb ‘24 - 15 Feb ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Feb",
      date: "7",
      link: "/event/spiti",
    },
  ],
  instagram:
    "https://www.instagram.com/dhamijasamarth?igsh=MTMyd3ZwdmRodDU3cQ==",
};
