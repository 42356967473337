import React from "react";
import "./Thanks.css";
import Navbar from "../../commons/Navbar";
import { useMediaQuery } from "@mui/material";

export default function Thanks() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const thanksContainer = () => {
    return (
      <div
        style={{
          maxWidth: 468,
          margin: isMobile ? "0px 22px" : "auto",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={
            "https://s3-alpha-sig.figma.com/img/4547/8519/300feef9e7e0bf7bf6e593ff94328e0a?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bGRba0zKaHzOQkEfrwzxtHZM3sjHTJ35FWqUUBRghl0iv3UzBVxnszMIgaRUwdKQXsqUekPv9SoTdFWivWLSvOsr0mfAKMjQ1z2E68BazXijgfVroQEjcW~8iZ-FNSJpahYCPuGQzJ7zx3dz2GXbC2UQVIkHpNNAHkrCaEhQN0csMr4KyoEieTliy9SbzO44gLM0PD12luFKFGWX4Ukq5e7TibO7qYbwScRqV~DzGlAUdA9TgeX5s9wcn2EKAVctBDhHSZ4ydeVY7ImSN46bGdMxKzEyEwtYIBK~BxApBdpvO~ciUrpUozFAjITTeHEitCaVMAUBhd2MS5n47N3Bog__"
          }
          alt="thanks"
        />
        <p className="thanks-text">Thank You </p>
        <p className="thanks-subtext">
          Music gets uploaded to Spotify via a distributor. For a fee or
          commission, distributors will handle the licensing and distribution to
          Spotify and other streaming services, and pay you royalties when
          listeners stream.
        </p>
        <p className="thanks-subtext" style={{ marginTop: 51 }}>
          Check out our directory for our preferred and recommended providers:
        </p>
        <button className="thanks-button">Share</button>
      </div>
    );
  };
  return (
    <div style={{ height: "100vh" }}>
      <Navbar isMobile={isMobile} />
      {thanksContainer()}
    </div>
  );
}
