import React from "react";
import Navbar from "../../commons/Navbar";
import { useMediaQuery } from "@mui/material";
import "./Discover.css";
import "./DiscoverMobile.css";
import Aman from "../../resources/images/aman.webp";
import Samarth from "../../resources/images/samarth.webp";
import Creator1 from "../../resources/images/creator-1.webp";
import Creator2 from "../../resources/images/creator-2.webp";
import Creator3 from "../../resources/images/creator-3.webp";
import Creator4 from "../../resources/images/creator-4.webp";
// import Discover1 from "../../resources/images/discover-1.webp";
// import Discover2 from "../../resources/images/discover-2.webp";
// import Discover3 from "../../resources/images/discover-3.webp";
// import Discover4 from "../../resources/images/discover-4.webp";
import TestSpiti1 from "../../resources/images/test-spiti-1.jpg";
import TestSpiti2 from "../../resources/images/test-spiti-3.jpg";
import TestOther1 from "../../resources/images/test-other-1.jpg";
import TestOther2 from "../../resources/images/test-other-2.jpg";
import MapPoint from "../../resources/images/Map Point.svg";
import Calendar from "../../resources/images/calendar-2.svg";
import Bengaluru from "../../resources/images/blr.svg";
import Adventure from "../../resources/images/adventure.svg";
import Exit from "../../resources/images/exit.png";
import Days from "../../resources/images/days.png";
import Wellness from "../../resources/images/wellness.png";
import Culinary from "../../resources/images/culinary.png";
import Creative from "../../resources/images/creative.png";
import Nature from "../../resources/images/nature.png";
import Spiritual from "../../resources/images/spiritual.png";
import Spiti from "../../resources/images/spiti.png";
import Pushkar from "../../resources/images/pushkar.png";
import Delhi from "../../resources/images/delhi.png";
import Mumbai from "../../resources/images/mumbai.png";
import Mussoorie from "../../resources/images/mussoorie.png";
import Kodaikanal from "../../resources/images/kodaikanal.png";
import Ladakh from "../../resources/images/ladakh.png";

export default function Discover() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const topSection = () => {
    return (
      <div>
        <p className="discover-text">
          D<span className="discover-text-span">iscover</span>
        </p>
        <p className="discover-subtext">
          Thrive via unforgettable experiences, creating meaningful connections
          that extend beyond the exprience
        </p>
      </div>
    );
  };

  const sectionHeader = (text: string) => {
    return (
      <div>
        <p className="discover-section-header">{text}</p>
      </div>
    );
  };

  const creatorSection = () => {
    const creatorArr = [
      {
        id: "creator-01",
        img: Aman,
        link: "aman",
      },
      {
        id: "creator-02",
        img: Samarth,
        link: "samarth",
      },
      {
        id: "creator-03",
        img: Creator1,
        // link: "Creator1",
      },
      {
        id: "creator-04",
        img: Creator2,
        // link: "Creator2",
      },
      {
        id: "creator-05",
        img: Creator3,
        // link: "Creator4",
      },
      {
        id: "creator-06",
        img: Creator4,
        // link: "Creator4",
      },
      {
        id: "creator-05",
        img: Creator3,
        // link: "Creator4",
      },
      {
        id: "creator-06",
        img: Creator4,
        // link: "Creator4",
      },
    ];

    const creatorCard = (ctr: { id?: string; img: any; link?: string }) => {
      return (
        // <div className="creator-image-div" key={ctr.id}>
        <img
          src={ctr.img}
          alt="creator"
          className="creator-img"
          key={ctr.id}
          onClick={() => {
            if (ctr.link) {
              window.location.href = `/creator/${ctr.link}`;
            }
          }}
        />
        // </div>
      );
    };
    return (
      <div style={{ marginTop: 56 }}>
        {sectionHeader("Featured Creators")}
        <div className="creator-container">
          {creatorArr.map((i) => creatorCard(i))}
        </div>
      </div>
    );
  };

  const browseByCategory = () => {
    const browseArr = [
      {
        id: "browse-01",
        icon: Exit,
        text: "Escape",
        link: "spiti",
      },
      {
        id: "browse-02",
        icon: Days,
        text: "Multi-Day",
        link: "spiti",
      },
      {
        id: "browse-03",
        icon: Wellness,
        text: "Wellness",
        link: "spiti",
      },
      {
        id: "browse-04",
        icon: Culinary,
        text: "Culinary",
        link: "spiti",
      },
      {
        id: "browse-05",
        icon: Adventure,
        text: "Adventure",
        link: "spiti",
      },
      {
        id: "browse-06",
        icon: Spiritual,
        text: "Spiritual",
        link: "spiti",
      },
      {
        id: "browse-07",
        icon: Creative,
        text: "Creative",
        link: "spiti",
      },
      {
        id: "browse-08",
        icon: Nature,
        text: "Nature",
        link: "spiti",
      },
    ];

    const browseCard = (bro: {
      id: any;
      icon?: string;
      text?: string;
      link?: string;
    }) => {
      return (
        <div
          key={bro.id}
          className="browse-card-div"
          onClick={() => {
            if (bro.link) {
              window.location.href = `/discover/${bro.link}`;
            }
          }}
        >
          <img src={bro.icon} alt="icon" style={{ height: 55 }} />
          <p className="browse-card-text">{bro.text}</p>
        </div>
      );
    };
    return (
      <div style={{ marginTop: 74 }}>
        {sectionHeader("Browse By Category")}
        <div className="browse-container">
          {browseArr.map((i) => browseCard(i))}
        </div>
      </div>
    );
  };

  const popularExp = () => {
    const popularArr = [
      {
        image: TestSpiti1,
        title: "Overlanding Spiti Expedition",
        by: "By Aman, Samarth",
        date: "Jun 21, 2024 - Jun 28, 2024",
        location: "Spiti Valley, Himachal Pradesh",
        link: "spiti",
      },
      {
        image: TestOther1,
        title: "Pushkar Holi Retreat",
        by: "By Aman, Samarth",
        date: "Mar 26, 2024 - Mar 31, 2024",
        location: "Pushkar, Rajasthan",
        link: "pushkar",
      },
      {
        image: TestSpiti2,
        title: "Overlanding Spiti Expedition",
        by: "By Aman, Samarth",
        date: "Sept 20, 2024 - Sept 27, 2024",
        location: "Spiti Valley, Himachal Pradesh",
        link: "spiti",
      },
      {
        image: TestOther2,
        title: "Pushkar Holi Retreat",
        by: "By Aman, Samarth",
        date: "Mar 14, 2024 - Mar 19, 2025",
        location: "Pushkar, Rajasthan",
        link: "pushkar",
      },
    ];

    const popularCard = (pc: {
      image: any;
      title?: string;
      by?: string;
      date?: string;
      location?: string;
      link?: string;
    }) => {
      return (
        <div
          className="popular-card-div"
          onClick={() => {
            if (pc.link) {
              window.location.href = `/event/${pc.link}`;
            }
          }}
        >
          <img className="popular-card-img" src={pc.image} alt="popular-card" />
          <div className="popular-card-bottom">
            <p className="popular-bottom-section-header">{pc.title}</p>
            <div
              className="popular-bottom-section-subdiv"
              style={{ marginTop: 16 }}
            >
              <div className="popular-creator-div">
                <img
                  src={Aman}
                  style={{ zIndex: 2 }}
                  alt="Creator"
                  className="popular-creator-img"
                />
                <img
                  src={Samarth}
                  style={{ zIndex: 1, marginLeft: -10, objectPosition: "left" }}
                  alt="Creator"
                  className="popular-creator-img"
                />
              </div>
              <p
                className="popular-bottom-section-by"
                style={{ marginLeft: 4 }}
              >
                {pc.by}
              </p>
            </div>
            <div
              className="popular-bottom-section-subdiv"
              style={{ marginTop: 12 }}
            >
              <img src={MapPoint} alt="map-point" />
              <p className="popular-bottom-section-location">{pc.location}</p>
            </div>
            <div
              className="popular-bottom-section-subdiv"
              style={{ marginTop: 12 }}
            >
              <img src={Calendar} alt="calendar" />
              <p className="popular-bottom-section-location">{pc.date}</p>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div style={{ paddingTop: 74 }}>
        {sectionHeader("Popular Experiences")}
        <div className="popular-card-container">
          {popularArr.map((i) => popularCard(i))}
        </div>
      </div>
    );
  };

  const locations = () => {
    const locArr = [
      {
        id: "loc-01",
        text: "Spiti Valley",
        ongoing: "6 trips ongoing",
        happening: "12 events happening",
        link: "/discover/spiti",
        icon: Spiti,
      },

      {
        id: "loc-02",
        text: "Pushkar",
        ongoing: "6 trips ongoing",
        happening: "12 events happening",
        link: "/discover/pushkar",
        icon: Pushkar,
      },
      {
        id: "loc-03",
        text: "New Delhi",
        ongoing: "0 trips ongoing",
        happening: "0 events happening",
        icon: Delhi,
      },
      {
        id: "loc-04",
        text: "Bangalore",
        ongoing: "0 trips ongoing",
        happening: "0 events happening",
        icon: Bengaluru,
      },
      {
        id: "loc-05",
        text: "Mumbai",
        ongoing: "0 trips ongoing",
        happening: "0 events happening",
        icon: Mumbai,
      },
      {
        id: "loc-06",
        text: "Mussoorie",
        ongoing: "0 trips ongoing",
        happening: "0 events happening",
        icon: Mussoorie,
      },
      {
        id: "loc-07",
        text: "Ladakh",
        ongoing: "0 trips ongoing",
        happening: "0 events happening",
        icon: Ladakh,
      },
      {
        id: "loc-08",
        text: "Kodaikanal",
        ongoing: "0 trips ongoing",
        happening: "0 events happening",
        icon: Kodaikanal,
      },
    ];
    const locationCard = (loc: {
      id: string;
      text: string;
      ongoing: string;
      happening: string;
      link?: string;
      icon: string;
    }) => {
      return (
        <div
          className="location-card-container"
          onClick={() => {
            if (loc.link) {
              window.location.href = loc.link;
            }
          }}
        >
          <img alt="icon" src={loc.icon} style={{ height: 55 }} />
          <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
            <p className="location-text">{loc.text}</p>
            <p className="location-text-sub" style={{ fontWeight: 400 }}>
              {loc.ongoing}
            </p>
            <p className="location-text-sub" style={{ fontWeight: 400 }}>
              {loc.happening}
            </p>
          </div>
        </div>
      );
    };
    return (
      <div style={{ marginTop: 74 }}>
        {sectionHeader("Locations")}
        <div className="location-container">
          {locArr.map((i) => locationCard(i))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar isMobile={isMobile} />
      <div style={{ padding: isMobile ? "0px 20px 20px" : "0px 50px 66px" }}>
        {topSection()}
        {creatorSection()}
        {browseByCategory()}
        {popularExp()}
        {locations()}
      </div>
    </div>
  );
}
