import React, { useCallback, useEffect } from "react";
import InstaLogin from "../../resources/images/insta-login.svg";
import TwitterLogin from "../../resources/images/twitter-login.svg";
import AppleLogin from "../../resources/images/apple-login.svg";
import FacebookLogin from "../../resources/images/facebook-login.svg";
import GoogleLogin from "../../resources/images/google-login.svg";
import "./Auth.css";

export default function SocialLogin() {
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google?.accounts) {
        clearInterval(interval); // Stop checking once google.accounts is available
        initializeGoogleSignIn();
      }
    }, 100);

    return () => clearInterval(interval); // Cleanup
    // Initialize the Google Sign-In button
  }, []);

  const initializeGoogleSignIn = useCallback(() => {
    window.google?.accounts.id.initialize({
      client_id:
        "218213585652-nc54ionui6p9kv6fjft15ct5bk4era95.apps.googleusercontent.com",
      callback: handleCredentialResponse, // Function to handle login success
    });

    // Render the Google Sign-In button
    const btn = document.getElementById("googleSignInDiv");
    if (btn) {
      window.google?.accounts.id.renderButton(btn, {
        theme: "outline",
        size: "large",
        type: "standard",
      });
    }
  }, []);

  useEffect(() => {
    // Initialize Google Identity Services
    const loadGoogleScript = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    };

    if (!window.google) {
      loadGoogleScript();
    } else {
      initializeGoogleSignIn();
    }
  }, [initializeGoogleSignIn]);
  const handleCredentialResponse = (response: any) => {
    console.log(response.credential);
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <p
        className="or_text"
        style={{ marginBottom: "14px", marginTop: "14px" }}
      >
        Or
      </p>
      <div className="insta-login-container">
        <img src={InstaLogin} alt="insta-login" />
        <p className="insta-login-text">Continue with Instagram</p>
      </div>
      <div
        style={{
          display: "flex",
          gap: 5,
          alignItems: "center",
          justifyContent: "center",
          marginTop: 14,
        }}
      >
        <div className="login-icon-wrapper">
          <img src={TwitterLogin} alt="twitter-login" />
        </div>
        <div className="login-icon-wrapper">
          <img src={AppleLogin} alt="apple-login" />
        </div>
        <div className="login-icon-wrapper">
          <img src={FacebookLogin} alt="facebook-login" />
        </div>
        <div className="login-icon-wrapper">
          <img
            src={GoogleLogin}
            alt="google-login"
            // onClick={handleLoginClick}
          />
        </div>
      </div>
    </div>
  );
}
