import React from "react";
import { html } from "./tnchtml";
export default function Tnc() {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{ padding: 40 }}
    ></div>
  );
}
