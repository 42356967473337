import { useMediaQuery } from "@mui/material";
import React from "react";
import LoginFlow from "./LoginFlow";
import RightScreen from "./RightScreen";

export default function LoginCreator() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <div className="home_container">
      <LoginFlow showNav={true} />
      {!isMobile && <RightScreen />}
    </div>
  );
}
