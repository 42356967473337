import Navbar from "../../commons/Navbar";
import { useMediaQuery } from "@mui/material";
import TestCity from "../../resources/images/test-city.png";
import "./ExploreCity.css";
import Map from "../../resources/images/map1.png";
import Calendar from "../../resources/images/calendar-3.svg";
import { Key } from "react";
import "./ExploreCityMobile.css";
import { spitiData, otherData } from "./helper";
import { useParams } from "react-router-dom";

export default function ExploreCity() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const params = useParams();
  const data = params.id === "spiti" ? spitiData : otherData;
  const topSection = () => {
    return (
      <div className="city-top-section">
        <div className="city-top-section-left">
          <div className="city-top-section-text-wrapper">
            <div className="city-top-section-text-subwrapper">
              <p className="city-top-section-heading">{data.title} : </p>
              <p className="city-top-section-subheading">{data.subtitle}</p>
            </div>
            <p className="city-top-section-text">{data.description}</p>
          </div>
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <img
            src={data.topImg}
            alt="City"
            style={{
              height: 574,
              objectFit: "cover",
              width: "calc(100vw - 264px)",
            }}
          />
        </div>
      </div>
    );
  };

  const mapSection = () => {
    return (
      <div
        className="map-section-wrapper"
        style={{
          backgroundImage: `url(${Map})`,
        }}
      >
        <div className="explore-city-subscribe-wrapper">
          <p className="explore-city-subscribe-heading">{data.title}</p>
          {!isMobile && (
            <p className="explore-city-subscribe-subheading">
              Join the insiders - subscribe to get notified about {data.title}’s
              thriving experiences!
            </p>
          )}
          <button className="explore-city-subscribe-button">SUBSCRIBE</button>
        </div>
      </div>
    );
  };

  const experienceSection = () => {
    const ExperienceCard = ({
      image,
      title,
      subtitle,
      description,
      dates,
      by,
      creatorImage,
      days,
      month,
      date,
      datesMobile,
    }: any) => {
      const rightSection = () => {
        return (
          <div className="experience-card-right-section">
            <div className="experience-card-creator-image-section">
              {creatorImage.map(
                (image: string | undefined, index: Key | null | undefined) => (
                  <img
                    src={image}
                    alt="Creator"
                    key={index}
                    className={`city-creator-image-${index}`}
                  />
                )
              )}
            </div>
            <p className="experience-card-by">{by}</p>
          </div>
        );
      };
      return (
        <div
          className="experience-card-wrapper"
          onClick={() => {
            window.location.href = data.link;
          }}
        >
          <div className="experience-card-left-section">
            <div className="experience-card-month-wrapper">
              <p className="experience-card-month">{month}</p>
              <p className="experience-card-date">{date}</p>
            </div>
            {!isMobile && <div className="horizontal-line" />}
            {!isMobile && (
              <div className="experience-card-title-wrapper">
                <p className="experience-card-title">{title}</p>
                <p className="experience-card-days">{days}</p>
              </div>
            )}
          </div>
          <div className="horizontal-line-painted" />

          <img
            src={image}
            alt="Experience"
            className="experience-card-image-section"
          />
          <div className="experience-card-middle-section">
            <p className="experience-card-subtitle">{subtitle}</p>
            {!isMobile && (
              <p className="experience-card-description">{description}</p>
            )}
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <img src={Calendar} alt="Calendar" />
              <p className="experience-card-dates">
                {isMobile ? datesMobile : dates}
              </p>
            </div>
            {isMobile && rightSection()}
          </div>
          {!isMobile && rightSection()}
        </div>
      );
    };
    return (
      <div style={{ marginTop: 59, marginBottom: 63 }}>
        <p className="experience-section-heading">Experiences</p>
        <div className="experience-section-cards-wrapper">
          {data.experienceCards.map(
            (card: any, index: Key | null | undefined) => (
              <ExperienceCard key={index} {...card} />
            )
          )}
        </div>
      </div>
    );
  };

  const topSectionMobile = () => {
    return (
      <div className="city-top-section-mobile-wrapper">
        <img
          src={TestCity}
          alt="City"
          className="city-top-section-mobile-image"
        />
        <div className="city-top-section-mobile-text-wrapper">
          <p className="city-top-section-mobile-heading">New Delhi : </p>
          <p className="city-top-section-mobile-subheading">
            Where Every Moment is an Experience
          </p>
          <p className="city-top-section-mobile-text">
            New Delhi is an experiential hub that blends rich history and modern
            culture. From iconic monuments like the Red Fort to immersive food
            walks through vibrant markets, the city offers unique adventures for
            every traveler
          </p>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Navbar isMobile={isMobile} />
      {!isMobile && topSection()}
      {isMobile && topSectionMobile()}
      <div
        style={{ padding: isMobile ? "0px 12px" : "0px 44px", marginTop: 59 }}
      >
        {mapSection()}
        {isMobile && (
          <p className="city-subscribe-description-mobile">
            Join the insiders - subscribe to get notified about New Delhi’s
            thriving experiences!
          </p>
        )}
        {experienceSection()}
      </div>
    </div>
  );
}
