import React from "react";
import RightScreen from "./RightScreen";
import AuthFlow from "./AuthFlow";
import { useMediaQuery } from "@mui/material";

export default function SignupCreator() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <div className="home_container">
      <AuthFlow showNav={true} />
      {!isMobile && <RightScreen />}
    </div>
  );
}
