import React from "react";
import HomeBackground2 from "../../resources/images/home-background-2.png";

export default function RightScreen() {
  return (
    <div>
      <div className="home-right-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 108,
            marginTop: 86,
          }}
        >
          <img
            src={HomeBackground2}
            alt="background"
            style={{ alignSelf: "flex-start" }}
          />
          <div>
            <div className="details-home-container">
              <div>
                <div className="details-home-div">
                  <p className="details-home-div-text">50 +</p>
                </div>
                <p className="details-home-text">Creators</p>
              </div>
              <div>
                <div className="details-home-div" style={{ width: 178 }}>
                  <p className="details-home-div-text">100 +</p>
                </div>
                <p className="details-home-text">Resources Listed</p>
              </div>
              <div>
                <div className="details-home-div" style={{ width: 200 }}>
                  <p className="details-home-div-text">25 +</p>
                </div>
                <p className="details-home-text">Experiences Hosted</p>
              </div>
            </div>
            <div
              className="travel-explore-div"
              style={{ marginLeft: -186, marginTop: 55, padding: "12px 24px" }}
            >
              <p className="travel-explore-heading">Create With Us</p>
              <p className="travel-explore-subheading">
                ‘Creator’ describes those who, beyond influencers recognition,
                craft new experiences.
              </p>
              {/* <button
                className="discover-button"
                style={{ width: 257 }}
                onClick={() => {
                  window.location.href = "https://tally.so/r/mV1Mgg";
                }}
              >
                Create
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
