import TestCreator1 from "../resources/images/test-creator2.svg";
import TestCreator2 from "../resources/images/test-creator-2.svg";
import TestCreator3 from "../resources/images/test-creator-3.svg";
import Aman from "../resources/images/aman.webp";
import Samarth from "../resources/images/samarth.webp";
import TestReview from "../resources/images/test-review1.svg";

export const reviewArr = [
  {
    image: TestReview,
    star: 4,
    name: "Neha",
    text: "Spiti Valley is like stepping into another world. The stark, rugged mountains and the quiet, isolated villages make it feel like a hidden gem. If you're looking for untouched nature, this is the place to be",
    date: "September 22, 2023",
  },
  {
    image: Aman,
    star: 4,
    name: "Aman",
    text: "A trip to Spiti is for the true adventurer. The roads are tough, but the stunning views of snow-capped peaks, deep valleys, and ancient monasteries are worth it. It’s a remote, wild experience you won’t forget.",
    date: "September 22, 2023",
  },
  {
    image: Samarth,
    star: 4,
    name: "Samarth",
    text: "The calm and spirituality of the Buddhist monasteries in Spiti were a highlight for me. Key Monastery, especially, was an amazing place to visit. The whole valley has a serene vibe that’s perfect for those seeking peace.",
    date: "September 22, 2023",
  },
  {
    image: TestCreator1,
    star: 4,
    name: "Sagar",
    text: "The barren beauty of Spiti is breathtaking. From the turquoise waters of Chandratal Lake to the vast open skies, every moment felt like a scene from a movie. It's a dream for photographers and nature lovers alike.",
    date: "September 22, 2023",
  },
  {
    image: TestCreator2,
    star: 4,
    name: "Shubham",
    text: "Spiti’s remoteness is what makes it special. It’s far from the crowds, and you can really disconnect. Whether it's camping under the stars or exploring the rugged landscapes, it’s a refreshing break from the usual tourist spots.",
    date: "September 22, 2023",
  },
  {
    image: TestCreator3,
    star: 4,
    name: "Vijay",
    text: "The journey to Spiti can be challenging with its rough terrain and high altitude, but the rewards are immense. Gorgeous views, kind locals, and a deep sense of accomplishment make the trip absolutely worth it.",
    date: "September 22, 2023",
  },
];

export const reviewArr1 = [
  {
    image: TestReview,
    star: 4,
    name: "Neha",
    text: "Pushkar is an enchanting mix of culture and spirituality. The holy Pushkar Lake and the bustling ghats offer a peaceful atmosphere, while the Brahma Temple is a must-visit for a deeper spiritual experience",
    date: "September 22, 2023",
  },
  {
    image: Aman,
    star: 4,
    name: "Aman",
    text: "Pushkar is alive with color! The markets are filled with vibrant textiles, jewelry, and souvenirs. Visiting during the Pushkar Camel Fair was an unforgettable experience—so much energy, culture, and fun packed into one place!",
    date: "September 22, 2023",
  },
  {
    image: Samarth,
    star: 4,
    name: "Samarth",
    text: "Watching the sunset over Pushkar Lake was magical. The reflections on the water and the ringing temple bells in the background made for a peaceful, almost meditative moment. A perfect place to relax and soak in the beauty.",
    date: "September 22, 2023",
  },
  {
    image: TestCreator1,
    star: 4,
    name: "Sagar",
    text: "The markets in Pushkar are fantastic! From traditional Rajasthani handicrafts to beautiful silver jewelry, there’s so much to explore. Be sure to haggle for the best prices—it's part of the fun! A perfect place for picking up unique souvenirs.",
    date: "September 22, 2023",
  },
  {
    image: TestCreator2,
    star: 4,
    name: "Shubham",
    text: "Exploring the desert on a camel safari was the highlight of my trip to Pushkar. The experience of riding through the dunes at sunset was unforgettable, and the view of the city from afar was stunning. A must-do for anyone visiting!",
    date: "September 22, 2023",
  },
  {
    image: TestCreator3,
    star: 4,
    name: "Vijay",
    text: "The locals in Pushkar are incredibly welcoming and friendly. I loved learning about the local culture, enjoying authentic Rajasthani cuisine, and visiting the many temples around town. It’s a small town but rich in tradition and hospitality.",
    date: "September 22, 2023",
  },
];
