import React from "react";

export default function DataDeletionPolicy() {
  return (
    <div style={{ padding: 40 }}>
      <h1 style={{ textAlign: "center" }}>User Data Deletion Policy</h1>
      <div className="container">
        <h1>Data Deletion Policy</h1>
        <p>
          <strong>Last Updated:</strong> 30 November 2024
        </p>

        <h2>Introduction</h2>
        <p>
          At Thrivia, we are committed to respecting your privacy and ensuring
          the security of your personal data. This Data Deletion Policy outlines
          how users can request the deletion of their data, including
          information collected during account registration and platform usage.
        </p>

        <h2>Data We Collect</h2>
        <p>We collect the following types of data:</p>
        <ul>
          <li>
            <strong>Personal Information:</strong> Name, email address, phone
            number.
          </li>
          <li>
            <strong>Social Media Data:</strong> Data accessed when users connect
            their Facebook, Instagram, or Google accounts, such as profile
            information, permissions, and activity logs (subject to the
            permissions granted during the connection).
          </li>
        </ul>

        <h2>User Rights</h2>
        <p>Users have the right to:</p>
        <ul>
          <li>
            Request the deletion of their personal data stored on our platform.
          </li>
          <li>
            Revoke permissions granted to third-party accounts (Facebook,
            Instagram, Google) connected to our platform.
          </li>
        </ul>

        <h2>How to Request Data Deletion</h2>
        <p>To request data deletion:</p>
        <ol>
          <li>Log in to your account on Thrivia.</li>
          <li>
            Navigate to the <strong>Account Settings</strong> or{" "}
            <strong>Privacy Settings</strong> section.
          </li>
          <li>
            Select <strong>Delete My Data</strong> and follow the prompts to
            confirm your request.
          </li>
        </ol>
        <p>
          Alternatively, you may contact us directly at{" "}
          <a href="mailto:support@thrivia.com">support@thrivia.com</a> with the
          subject line “Data Deletion Request” and include the following
          details:
        </p>
        <ul>
          <li>Your full name and email address associated with the account.</li>
          <li>
            A clear statement indicating that you wish to delete your data.
          </li>
        </ul>

        <h2>What Happens After a Deletion Request?</h2>
        <ul>
          <li>
            <strong>Data Removal:</strong> Upon receiving a verified request, we
            will delete your personal information from our systems within 30
            days.
          </li>
          <li>
            <strong>Connected Accounts:</strong> Data obtained through connected
            accounts (Facebook, Instagram, Google) will also be deleted, and all
            integrations with these platforms will be revoked.
          </li>
          <li>
            <strong>Retention Exceptions:</strong> Certain data may be retained
            for compliance with legal obligations, fraud prevention, or dispute
            resolution, as required by applicable laws.
          </li>
        </ul>

        <h2>Third-Party Services</h2>
        <p>
          Data shared with third-party services (e.g., Facebook, Instagram,
          Google) may still exist on their platforms. We recommend contacting
          these services directly to request data deletion from their systems.
        </p>

        <h2>Changes to This Policy</h2>
        <p>
          We may update this policy from time to time to reflect changes in
          legal requirements or our practices. We encourage users to review this
          policy periodically.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have questions or concerns about this policy or data deletion,
          please contact us:
        </p>
        <ul>
          <li>
            <strong>Email:</strong>{" "}
            <a href="mailto:support@thrivia.com">support@thrivia.com</a>
          </li>
        </ul>

        <p>By using our platform, you acknowledge and agree to this policy.</p>
      </div>
    </div>
  );
}
