import React from "react";

const inputBoxWithLabel = (
  label: string,
  margintop: number,
  type: string,
  props: any,
  error?: boolean,
  helperText?: string
) => {
  return (
    <div className={`input_box_with_label `} style={{ marginTop: margintop }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="input_label">{label}</p>
        {helperText && (
          <p
            className={`input_helper_text ${
              error ? "input_helper_text_error" : ""
            }`}
          >
            {helperText}
          </p>
        )}
      </div>
      <input
        type={type}
        className={`input_box ${error ? "input-box-error" : ""}`}
        {...props}
      />
    </div>
  );
};

export default function InputBoxWithLabel(
  props: {
    label: string;
    margintop: number;
    type: string;
    inputProps: any;
    error: boolean | undefined;
    helperText: string | undefined;
  }
  // label,
  // margintop,
  // type,
  // inputProps,
  // error?,
  // helperText?,
) {
  return (
    <div>
      {inputBoxWithLabel(
        props.label,
        props.margintop,
        props.type,
        props.inputProps,
        props.error,
        props.helperText
      )}
    </div>
  );
}
