import React from "react";
import LeftScreen from "./LeftScreen";
import AuthFlow from "./AuthFlow";
import { useMediaQuery } from "@mui/material";

export default function SignupExplorer() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <div className="home_container">
      {!isMobile && <LeftScreen />}
      <AuthFlow type="explorer" />
    </div>
  );
}
