import React from "react";
import Navbar from "../../commons/Navbar";
import { useMediaQuery } from "@mui/material";
import "./CreatorV2.css";
import Calendar from "../../resources/images/calendar-3.svg";
import Hero1 from "../../resources/images/hero1.svg";
import Hero2 from "../../resources/images/hero2.svg";
import Hero3 from "../../resources/images/hero3.svg";
import Hero4 from "../../resources/images/hero4.svg";
import EmptyStar from "../../resources/images/empty-star.svg";
import FilledStar from "../../resources/images/filled-star.svg";
import Location from "../../resources/images/location2.svg";
import ReviewIcon1 from "../../resources/images/review-icon-1.svg";
import ReviewIcon2 from "../../resources/images/review-icon-2.svg";
import ReviewIcon3 from "../../resources/images/review-icon-3.svg";
import FillStar from "../../resources/images/review-icon-4.svg";
import ReviewIcon5 from "../../resources/images/value-icon.svg";
import EmtStar from "../../resources/images/emt-star.svg";
import { aman, samarth } from "./helper";
import { useParams } from "react-router-dom";
import "../event/Event.css";
import { reviewArr } from "../helper";
import Insta from "../../resources/images/insta.svg";

export default function CreatorV2() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const params = useParams();
  const data = params.id === "aman" ? aman : samarth;
  const headerSection = (header: string) => {
    return <p className="event-page-header-text">{header}</p>;
  };

  const experienceSection = () => {
    const experienceCards = data.exps;

    const ExperienceCard = ({
      image,
      title,
      subtitle,
      description,
      dates,
      by,
      creatorImage,
      days,
      month,
      date,
      datesMobile,
      link,
    }: any) => {
      const rightSection = () => {
        return (
          <div className="experience-card-right-section">
            <div className="experience-card-creator-image-section">
              {creatorImage.map((image: string | undefined, index: number) => (
                <img
                  src={image}
                  alt="Creator"
                  key={index}
                  className={`city-creator-image-${index}`}
                />
              ))}
            </div>
            <p className="experience-card-by">{by}</p>
          </div>
        );
      };
      return (
        <div
          className="experience-card-wrapper"
          onClick={() => {
            window.location.href = link;
          }}
        >
          <div className="experience-card-left-section">
            <div className="experience-card-month-wrapper">
              <p className="experience-card-month">{month}</p>
              <p className="experience-card-date">{date}</p>
            </div>
            {!isMobile && <div className="horizontal-line" />}
            {!isMobile && (
              <div className="experience-card-title-wrapper">
                <p className="experience-card-title">{title}</p>
                <p className="experience-card-days">{days}</p>
              </div>
            )}
          </div>
          <div className="horizontal-line-painted" />

          <img
            src={image}
            alt="Experience"
            className="experience-card-image-section"
          />
          <div className="experience-card-middle-section">
            <p className="experience-card-subtitle">{subtitle}</p>
            {!isMobile && (
              <p className="experience-card-description">{description}</p>
            )}
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <img src={Calendar} alt="Calendar" />
              <p className="experience-card-dates">
                {isMobile ? datesMobile : dates}
              </p>
            </div>
            {isMobile && rightSection()}
          </div>
          {!isMobile && rightSection()}
        </div>
      );
    };
    return (
      <div style={{ marginTop: 60 }}>
        <p className="experience-section-heading">Experiences</p>
        <div className="experience-section-cards-wrapper">
          {experienceCards.map((card, index) => (
            <ExperienceCard key={index} {...card} />
          ))}
        </div>
      </div>
    );
  };

  const heroImages = () => {
    const imgArr = [Hero1, Hero2, Hero3, Hero4];
    return (
      <div className="hero-images-container">
        <img
          className="hero-img-primary"
          alt="cover"
          src={imgArr[0]}
          style={{ flexGrow: 318 }}
        />
        <div
          className="hero-image-secondary-container"
          style={{ flexGrow: 429 }}
        >
          <img className="hero-img-secondary" alt="cover" src={imgArr[1]} />
          <img className="hero-img-secondary" alt="cover" src={imgArr[2]} />
        </div>
        <img
          className="hero-img-primary"
          alt="cover"
          src={imgArr[3]}
          style={{ flexGrow: 394 }}
        />
      </div>
    );
  };

  const pastTripAlbum = () => {
    return (
      <div style={{ marginTop: 60 }}>
        <p className="experience-section-heading">Past Trip Album</p>
        {heroImages()}
      </div>
    );
  };

  const tripReviews = () => {
    const reviewIconArr = [
      {
        icon: ReviewIcon2,
        rating: 4.9,
        title: "Cleanliness",
      },
      {
        icon: ReviewIcon1,
        rating: 4.9,
        title: "Accuracy",
      },
      {
        icon: ReviewIcon3,
        rating: 4.9,
        title: "Checkin",
      },
      {
        icon: Location,
        rating: 4.9,
        title: "Location",
      },
      {
        icon: ReviewIcon5,
        rating: 4.9,
        title: "Value",
      },
    ];

    const leftSection = () => {
      return (
        <div className="overview-review-container-div-right">
          {reviewIconArr.map((i) => (
            <div className="overview-review-container-div-right-div">
              <div className="overview-review-container-div-right-div-icon">
                <img src={i.icon} alt="review-icon" className="review-icon" />
              </div>
              <p className="overview-review-container-div-right-div-text">
                {i.title}
              </p>
              <p className="overview-review-container-div-right-div-subtext">
                {i.rating}
              </p>
            </div>
          ))}
        </div>
      );
    };
    return (
      <div style={{ marginTop: 60 }}>
        {headerSection("Reviews (24)")}
        <div className="overview-review-container">
          <div className="overview-review-container-div-left">
            <p className="overview-review-rating-text">4.1</p>
            <div className="overview-review-rating-div">
              <img src={FilledStar} alt="filled-star" />
              <img src={FilledStar} alt="filled-star" />
              <img src={FilledStar} alt="filled-star" />
              <img src={FilledStar} alt="filled-star" />
              <img src={EmptyStar} alt="empty-star" />
            </div>
            <p className="overview-review-rating-subtext">24 ratings</p>
          </div>
          {!isMobile && leftSection()}
        </div>
        <div className="detail-review-container">
          {[...reviewArr].map((i) => (
            <div className="detail-review-container-div">
              <div className="detail-review-container-div-image-wrapper">
                <img
                  src={i.image}
                  alt="review"
                  className="detail-review-container-div-image"
                />
                <p className="detail-review-container-div-name">{i.name}</p>
              </div>
              <div className="detail-review-container-div-date-wrapper">
                {i.star && (
                  <div className="detail-review-container-div-star-wrapper">
                    <img src={FillStar} alt="filled-star" />
                    <img src={FillStar} alt="filled-star" />
                    <img src={FillStar} alt="filled-star" />
                    <img src={FillStar} alt="filled-star" />
                    <img
                      src={EmtStar}
                      alt="empty-star"
                      style={{ opacity: 0.24 }}
                    />
                  </div>
                )}
                <p className="detail-review-container-div-date">{i.date}</p>
              </div>
              <p className="detail-review-container-div-text">{i.text}</p>
              <p className="detail-review-container-div-read-more">Show more</p>
            </div>
          ))}
        </div>
        {isMobile && leftSection()}
        {!isMobile && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="show-more-button">Load more reviews</button>
          </div>
        )}
      </div>
    );
  };

  const topSection = () => {
    const scrollToExperiences = () => {
      document
        .querySelector(".experience-section-heading")
        ?.scrollIntoView({ behavior: "smooth" });
    };
    return (
      <div className="top-section-container">
        <div
          className="top-section-container-left"
          style={{ backgroundImage: `url(${data.image})` }}
        >
          <div className="top-section-container-left-overlay-container">
            <div>
              <div className="top-section-container-left-overlay">
                <p className="top-section-container-left-overlay-text">25+</p>
              </div>
              <p className="top-section-container-left-text">Trips Hosted</p>
            </div>
            <div>
              <div className="top-section-container-left-overlay">
                <p className="top-section-container-left-overlay-text">5+</p>
              </div>
              <p className="top-section-container-left-text">Locations</p>
            </div>
            <div>
              <div className="top-section-container-left-overlay">
                <p className="top-section-container-left-overlay-text">2000+</p>
              </div>
              <p className="top-section-container-left-text">Followers</p>
            </div>
            <div>
              <div
                className="top-section-container-left-overlay"
                style={{ cursor: "pointer", backgroundColor: "white" }}
                onClick={() => {
                  window.open(data.instagram);
                }}
              >
                {/* <p className="top-section-container-left-overlay-text"></p> */}
                <img src={Insta} alt="instagram" style={{ width: 42 }} />
              </div>
              <p className="top-section-container-left-text">Instagram</p>
            </div>
          </div>
        </div>
        <div className="top-section-container-right">
          <p className="top-section-container-right-name">{data.name}</p>
          <p className="top-section-container-right-description">
            {data.description}
          </p>
          <p className="top-section-container-right-description">
            Check out our directory for our preferred and recommended providers:
          </p>
          <button
            className="top-section-container-right-button"
            onClick={scrollToExperiences}
          >
            Browse Experiences
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar isMobile={isMobile} />

      <div style={{ padding: isMobile ? "0px 12px" : "0px 44px" }}>
        {topSection()}
        {experienceSection()}
        {pastTripAlbum()}
        {tripReviews()}
      </div>
    </div>
  );
}
