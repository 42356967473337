import { useMediaQuery } from "@mui/material";
import React from "react";

import HomeBackground1 from "../../resources/images/home-background.png";
import ThriviaLogo from "../../resources/images/thrivia_logo.png";
import HomeBackground3 from "../../resources/images/white-background.svg";

export default function LeftScreen() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <div style={{ flex: 1 }}>
      <div
        className="home-left-container"
        style={{
          backgroundImage: isMobile
            ? `url(${HomeBackground1})`
            : `url(${HomeBackground1})`,
        }}
      >
        <div
          style={{
            paddingTop: 38,
            marginLeft: 74,
            display: "flex",
            alignItems: "center",
            gap: 20,
            cursor: "pointer",
          }}
        >
          <img src={ThriviaLogo} alt="Logo" />
          <p className="nav_logo_p">Thrivia</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 354,
              height: 709,
              // borderRadius: "100% 0% 0% 100%",
              // background: "white",
              backgroundImage: `url(${HomeBackground3})`,
              margin: "0px 0px 80px ",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div className="travel-explore-div">
              <p className="travel-explore-heading">Travel and Explore</p>
              <p className="travel-explore-subheading">
                Empowering creators to craft, enthusiasts to discover immersive
                experiences
              </p>
              {/* <button
                className="discover-button"
                onClick={() => {
                  window.location.href = "/discover";
                }}
              >
                Discover
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
