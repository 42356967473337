import TestSpiti1 from "../../resources/images/test-spiti-1.jpg";
import Aman from "../../resources/images/aman.webp";
import Samarth from "../../resources/images/samarth.webp";
import TestSpiti2 from "../../resources/images/test-spiti-2.jpg";
import TestSpiti3 from "../../resources/images/test-spiti-3.jpg";
import TestSpiti4 from "../../resources/images/test-spiti-4.jpg";
import TestOther1 from "../../resources/images/test-other-1.jpg";
import TestOther2 from "../../resources/images/test-other-2.jpg";
import TestOther3 from "../../resources/images/test-other-3.jpg";
import TestOther4 from "../../resources/images/test-other-4.jpg";

export const spitiData = {
  link: "/event/spiti",
  topImg: TestSpiti1,
  title: "Spiti Valley",
  subtitle: "A Journey Beyond the Ordinary",
  description:
    "Spiti Valley offers an unmatched blend of adventure and serenity, with towering mountains, ancient monasteries, and rugged landscapes. From high-altitude treks to soul-searching cultural experiences, Spiti promises a journey that takes you far beyond the usual",
  experienceCards: [
    {
      image: TestSpiti1,
      title: "Retreat with Design Team",
      subtitle: "Overlanding Spiti Expedition",
      description:
        "Embark on an epic overlanding expedition through the rugged terrains of Spiti Valley.",
      dates: "Fri, 10 Jan 2024 - Fri, 17 Jan 2024",
      datesMobile: "10 Jan ‘24 - 17 Jan ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Jan",
      date: "10",
    },
    {
      image: TestSpiti2,
      title: "Adventure with friends",
      subtitle: "Spiti Valley Expedition",
      description:
        "Embark on an epic overlanding expedition through the rugged terrains of Spiti Valley.",
      dates: "Fri, 24 Jan 2024 - Sat, 1 Feb 2024",
      datesMobile: "24 Jan ‘24 - 1 Feb ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Jan",
      date: "24",
    },
    {
      image: TestSpiti3,
      title: "Meditation in solitude",
      subtitle: "Meditation Retreat in Spiti Valley",
      description:
        "Embark on an epic overlanding expedition through the rugged terrains of Spiti Valley.",
      dates: "Fri, 7 Feb 2024 - Sat, 15 Feb 2024",
      datesMobile: "7 Feb ‘24 - 15 Feb ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Feb",
      date: "7",
    },
    {
      image: TestSpiti4,
      title: "Fun corporate retreat",
      subtitle: "Corporate Retreat in Spiti Valley",
      description:
        "Embark on an epic overlanding expedition through the rugged terrains of Spiti Valley.",
      dates: "Fri, 21 Feb 2024 - Sat, 1 Mar 2024",
      datesMobile: "21 Feb ‘24 - 1 Mar ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Feb",
      date: "21",
    },
  ],
};

export const otherData = {
  link: "/event/pushkar",
  topImg: TestOther4,
  title: "Pushkar",
  subtitle: "A Cultural Oasis of Festivity and Serenity",
  description:
    "Pushkar blends spirituality with vibrant celebrations, from its sacred lake and temples to the lively Pushkar Holi, where streets burst with color. Whether joining the festivities or exploring ancient markets, Pushkar offers an experience that transcends the ordinary.",
  experienceCards: [
    {
      image: TestOther1,
      title: "Retreat with Design Team",
      subtitle: "Experiential Retreat In Pushkar",
      description:
        "Experience the spiritual essence of Pushkar through a thoughtfully curated retreat",
      dates: "Fri, 10 Jan 2024 - Fri, 17 Jan 2024",
      datesMobile: "10 Jan ‘24 - 17 Jan ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Jan",
      date: "10",
    },
    {
      image: TestOther2,
      title: "Journey with Friends",
      subtitle: "Pushkar Expedition",
      description:
        "Experience the spiritual essence of Pushkar through a thoughtfully curated retreat",
      dates: "Fri, 24 Jan 2024 - Sat, 1 Feb 2024",
      datesMobile: "24 Jan ‘24 - 1 Feb ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Jan",
      date: "24",
    },
    {
      image: TestOther3,
      title: "Solo Spiritual Journey",
      subtitle: "Holi Retreat in Pushkar",
      description:
        "Experience the spiritual essence of Pushkar through a thoughtfully curated retreat",
      dates: "Fri, 7 Feb 2024 - Sat, 15 Feb 2024",
      datesMobile: "7 Feb ‘24 - 15 Feb ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Feb",
      date: "7",
    },
    {
      image: TestOther4,
      title: "Fun corporate retreat",
      subtitle: "Corporate Retreat in Pushkar",
      description:
        "Experience the spiritual essence of Pushkar through a thoughtfully curated retreat",
      dates: "Fri, 21 Feb 2024 - Sat, 1 Mar 2024",
      datesMobile: "21 Feb ‘24 - 1 Mar ‘24",
      by: "By Aman, Samarth ",
      creatorImage: [Aman, Samarth],
      days: "7 Days, 6 Nights",
      month: "Feb",
      date: "24",
    },
  ],
};
